var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-conference","hideHeaderClose":"","ok-title":_vm.$t('Save'),"cancel-title":_vm.$t('Abort'),"cancel-variant":"outline-secondary","size":"lg","centered":""},on:{"show":_vm.showModal,"shown":_vm.afterShowModal,"hidden":_vm.hiddenModal,"ok":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)},"cancel":_vm.resetModal}},[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('b-row',[_c('b-col',{staticClass:"modalTitle",attrs:{"cols":"12"}},[_c('h2',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t("conference.Conference")))])])],1),_c('validation-observer',{ref:"conferences"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('conference.Conference_hall') + ' *'}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('conference.Conference_hall')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.conference_hall},model:{value:(_vm.conference.conference_hall.id),callback:function ($$v) {_vm.$set(_vm.conference.conference_hall, "id", $$v)},expression:"conference.conference_hall.id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Link *","label-for":"link"}},[_c('validation-provider',{attrs:{"rules":"required","name":"link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"link","placeholder":"url"},model:{value:(_vm.conference.url),callback:function ($$v) {_vm.$set(_vm.conference, "url", $$v)},expression:"conference.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('conference.start_time') + ' *',"label-for":"date-time-start"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('conference.start_time')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date-time-start","placeholder":"YYYY-MM-DD HH:MM","config":{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    time_24hr: true,
                  }},model:{value:(_vm.conference.start),callback:function ($$v) {_vm.$set(_vm.conference, "start", $$v)},expression:"conference.start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","hidden":""}},[_c('b-form-group',{attrs:{"label":_vm.$t('conference.end_time') + ' *',"label-for":"date-time-end"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('conference.end_time')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date-time-end","placeholder":"HH:MM","config":{
                    noCalendar: true,
                    enableTime: true,
                    dateFormat: 'H:i',
                    time_24hr: true,
                  }},model:{value:(_vm.conference.end),callback:function ($$v) {_vm.$set(_vm.conference, "end", $$v)},expression:"conference.end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","hidden":""}},[_c('b-form-group',{staticClass:"mt-2",attrs:{"label":_vm.$t('conference.Section') + ''}},[_c('validation-provider',{attrs:{"name":_vm.$t('conference.On_Demand')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"on_demand","name":"on_demand"},model:{value:(_vm.conference.on_demand),callback:function ($$v) {_vm.$set(_vm.conference, "on_demand", $$v)},expression:"conference.on_demand"}},[_vm._v(" "+_vm._s(_vm.$t("conference.On_Demand"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","hidden":""}},[_c('b-form-group',{attrs:{"label":"CTA","label-for":"cta"}},[_c('b-form-input',{attrs:{"id":"cta","placeholder":"","maxlength":"25"},model:{value:(_vm.conference.cta),callback:function ($$v) {_vm.$set(_vm.conference, "cta", $$v)},expression:"conference.cta"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('short_description'),"label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"stand_description","placeholder":_vm.$t('add_short_description'),"rows":"4","maxlength":"255"},model:{value:(_vm.conference.description),callback:function ($$v) {_vm.$set(_vm.conference, "description", $$v)},expression:"conference.description"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }