<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-card-code :title="$t('menu_name.webinar_live')">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-primary"
          class="mt-0 mt-md-2"
          @click="addConference()"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />{{
            $t("conference.Add_conference")
          }}
        </b-button>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t("search") }}</label>
              <b-form-input
                v-model="searchTerm"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="conferences"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          styleClass="vgt-table table-hover"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: id -->
            <span v-if="props.column.field == 'id'">
              <span> {{ props.row.id }}</span>
            </span>
            <!-- Column: hall -->
            <span v-if="props.column.field == 'hall'">
              <span> {{ props.row.hall }}</span>
            </span>
            <!-- Column: start date time -->
            <span v-if="props.column.field == 'start'">
              <span> {{ props.row.start }}</span>
            </span>
            <!-- Column: end date time -->
            <span v-if="props.column.field == 'end'">
              <span> {{ props.row.end }}</span>
            </span>
            <!-- Column: link -->
            <span v-if="props.column.field == 'link'">
              <b-link
                :href="props.row.url"
                target="_blank"
                style="font-weight: bold;"
              >
                <feather-icon icon="ExternalLinkIcon" class="mr-50" />
              </b-link>
            </span>
            <!-- Column: cta -->
            <span v-if="props.column.field == 'cta'">
              <span> {{ props.row.cta }}</span>
            </span>
            <!-- Column: description -->
            <span v-if="props.column.field == 'description'">
              <span> {{ props.row.description }}</span>
            </span>
            <!-- Column: on_demand -->
            <span v-if="props.column.field === 'on_demand'">
              <b-badge pill v-if="props.row.on_demand" variant="secondary">
                {{ $t("conference.On_Demand") }}
              </b-badge>
              <b-badge pill v-else variant="primary">
                Live
              </b-badge>
            </span>
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="editConference(props.row.id)">
                    <feather-icon icon="FileTextIcon" class="mr-50" />
                    <span>{{ $t("edit") }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteConference(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span> {{ $t("conference.Delete") }} </span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  {{ $t("showing") }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ $t("showing_pt2", { total_entries }) }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-code>
    </b-overlay>
    <modal-conference
      :conference_id="conference_id"
      @onSave="refreshConference"
    ></modal-conference>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BFormGroup,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormSelect,
  BOverlay,
  BBadge,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";

import ModalConference from "./edit-conference";

import { getAllConferences, deleteConferences } from "@api/conference";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BLink,
    BFormSelect,
    BPagination,
    BOverlay,
    BCardCode,
    VueGoodTable,
    BBadge,
    ModalConference,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      columns: [
        {
          label: "id",
          field: "id",
          hidden: true,
        },
        {
          label: this.$t("conference.Conference_hall"),
          field: "hall",
          width: "150px",
        },
        {
          label: this.$t("conference.Start"),
          field: "start",
          width: "150px",
        },
        /* {
          label: this.$t("conference.End"),
          field: "end",
          width: "150px",
        }, */
        {
          label: "Link",
          field: "link",
          sortable: false,
          width: "100px",
        },
        /* {
          label: "CTA",
          field: "cta",
          sortable: false,
          width: "150px",
        }, */
        {
          label: this.$t("conference.Description"),
          field: "description",
          sortable: false,
        },
        {
          label: this.$t("conference.On_Demand"),
          field: "on_demand",
          sortable: false,
        },
        {
          field: "action",
          label: this.$t("field_label_actions"),
          sortable: false,
          width: "100px",
        },
      ],
      searchTerm: "",
      conferences: [],
      pageLength: 10,
      total_entries: null,
      conference_id: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    refreshConference() {
      this.getData();
    },
    addConference() {
      this.conference_id = null;
      this.$bvModal.show("modal-conference");
    },
    editConference(id) {
      this.conference_id = id;
      this.$bvModal.show("modal-conference");
    },
    deleteConference(id) {
      const $this = this;
      this.$swal({
        title:
          '<span class="font-weight-bolder">' +
          this.$t("alert_title_warning") +
          "</span>",
        icon: "warning",
        text: this.$t("conference.alert_content_text"),
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: this.$t("alert_confirm_button"),
        cancelButtonText: this.$t("alert_cancel_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$swal({
              icon: "success",
              title: this.$t("alert_title_success"),
              text: this.$t("conference.alert_success_text"),
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            deleteConferences(id)
              .then(() => {
                $this.getData();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getData() {
      const $this = this;
      getAllConferences()
        .then((data) => {
          $this.conferences = data;
          $this.total_entries = data.length;
          $this.show = false;
        })
        .catch((error) => {
          console.log(error);
          $this.show = false;
        });
    },
  },
};
</script>

<style></style>
