<template>
  <b-modal
    id="modal-conference"
    hideHeaderClose
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    @show="showModal"
    @shown="afterShowModal"
    @hidden="hiddenModal"
    @ok.prevent="saveData"
    @cancel="resetModal"
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder">{{ $t("conference.Conference") }}</h2>
        </b-col>
      </b-row>
      <validation-observer ref="conferences">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('conference.Conference_hall') + ' *'">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('conference.Conference_hall')"
                >
                  <b-form-select
                    v-model="conference.conference_hall.id"
                    :options="conference_hall"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Link *" label-for="link">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="link"
                >
                  <b-form-input
                    id="link"
                    placeholder="url"
                    v-model="conference.url"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('conference.start_time') + ' *'"
                :label-for="`date-time-start`"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('conference.start_time')"
                >
                  <flat-pickr
                    :id="`date-time-start`"
                    placeholder="YYYY-MM-DD HH:MM"
                    class="form-control"
                    v-model="conference.start"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      time_24hr: true,
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" hidden>
              <b-form-group
                :label="$t('conference.end_time') + ' *'"
                :label-for="`date-time-end`"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('conference.end_time')"
                >
                  <flat-pickr
                    :id="`date-time-end`"
                    placeholder="HH:MM"
                    class="form-control"
                    v-model="conference.end"
                    :config="{
                      noCalendar: true,
                      enableTime: true,
                      dateFormat: 'H:i',
                      time_24hr: true,
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" hidden>
              <b-form-group :label="$t('conference.Section') + ''" class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('conference.On_Demand')"
                >
                  <b-form-checkbox
                    id="on_demand"
                    name="on_demand"
                    v-model="conference.on_demand"
                  >
                    {{ $t("conference.On_Demand") }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" hidden>
              <b-form-group label="CTA" label-for="cta">
                <b-form-input
                  id="cta"
                  placeholder=""
                  v-model="conference.cta"
                  maxlength="25"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('short_description')"
                label-for="description"
              >
                <b-form-textarea
                  id="stand_description"
                  v-model="conference.description"
                  :placeholder="$t('add_short_description')"
                  rows="4"
                  maxlength="255"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BOverlay,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";

import {
  getHalls,
  createConferences,
  updateConferences,
  getConferences,
} from "@api/conference";

export default {
  props: ["conference_id"],
  name: "conference_modal",
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BOverlay,
    BFormSelect,
    BFormTextarea,
    flatPickr,
  },
  data() {
    return {
      show: true,
      conference_hall: [],
      conference: {
        conference_hall: {
          id: null,
          name: "",
        },
        start: null,
        end: null,
        url: "",
        cta: "",
        on_demand: 0,
        description: "",
      },
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    showModal() {
      const $this = this;
      this.conference_hall = [];
      getHalls()
        .then((data) => {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            $this.conference_hall.push({
              value: element.id,
              text: element.name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    afterShowModal() {
      const $this = this;
      localize("it");
      $this.show = true;
      if (this.conference_id != null) {
        getConferences(this.conference_id)
          .then((data) => {
            $this.conference = data;
            $this.show = false;
          })
          .catch((error) => {
            console.log(error);
            $this.show = false;
          });
      } else {
        $this.show = false;
        //HACK per per aver selezionato subito la hall corretta e unica
        $this.conference.conference_hall.id = 1;
      }
    },
    saveData() {
      const $this = this;
      this.$refs.conferences.validate().then((success) => {
        if (success) {
          $this.save();
        }
      });
      return false;
    },
    save() {
      const $this = this;
      $this.show = true;
      let formData = {
        conference_hall: $this.conference.conference_hall.id,
        start: $this.conference.start,
        end: $this.conference.start,
        url: $this.conference.url,
        cta: $this.conference.cta,
        on_demand: $this.conference.on_demand,
        description: $this.conference.description,
      };
      if ($this.conference_id == null) {
        createConferences(formData)
          .then(() => {
            this.$emit("onSave");
            this.$bvModal.hide("modal-conference");
            $this.show = false;
          })
          .catch((error) => {
            console.log(error);
            $this.show = false;
          });
      } else {
        updateConferences($this.conference_id, formData)
          .then(() => {
            this.$emit("onSave");
            this.$bvModal.hide("modal-conference");
            $this.show = false;
          })
          .catch((error) => {
            console.log(error);
            $this.show = false;
          });
      }
    },
    resetModal() {
      this.$bvModal.hide("modal-conference");
    },
    hiddenModal() {
      this.conference = {
        conference_hall: {
          id: null,
          name: "",
        },
        start: null,
        end: null,
        url: "",
        cta: "",
        on_demand: 0,
        description: "",
      };
    },
  },
};
</script>
